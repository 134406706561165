import { addDays } from '@/utils/dateTime/addDays';
import FILTERS_CONFIG from '@/model/modules/dashboardSSP/filtersConfig.js';
import SelectOption from '@/model/shared/SelectOption';
import currency from '@/filters/currency';
import { TODAY } from '@/utils/dateTime/today';
import {
  columnTypes,
  dateList,
  defaultColDef,
  defaultColumns,
  BREAKDOWN_COLUMNS,
  breakdown,
  rowClassRules,
  transformColumnsForAgGrid,
} from '../config';

const breakdownColumns = [
  BREAKDOWN_COLUMNS.NAME,
  BREAKDOWN_COLUMNS.DATE,
  BREAKDOWN_COLUMNS.COUNTRY,
  BREAKDOWN_COLUMNS.PLATFORM,
];
export const CONFIG = Object.freeze({
  DEAL_OTT: 'OTT',
  filters: [FILTERS_CONFIG.PLATFORM_DEAL_NAME, FILTERS_CONFIG.DEAL_COUNTRY_NAME, FILTERS_CONFIG.PLATFORM_ID],
  breakdown,
  totalsColumn: [
    new SelectOption('impressions', 'Impressions'),
    new SelectOption('clicks', 'Clicks'),
    new SelectOption('requests', 'Requests'),
  ],
  defaultDate: { startDate: addDays(TODAY, -1), endDate: addDays(TODAY, -1) },
  dateList,
  reportType: { deal: 'DEAL_LIST', day: 'DEAL_DAY' },
  currencyOptions: [new SelectOption('eur', 'EUR'), new SelectOption('usd', 'USD')],
  limitMaxDate: addDays(TODAY, -1),
  defaultColDef,
  columnTypes,
  rowClassRules,
  exportParams: {
    sheetName: 'Deals Ott Analytics',
    processCellCallback: params => {
      try {
        if (!params.value) return '-';
        return params.value?.currency ? `${currency(params.value.value, params.value.currency)}` : params.value;
      } catch (e) {
        return params.value;
      }
    },
  },
  headers: defaultColumns.map(transformColumnsForAgGrid),
  breakdown_headers: breakdownColumns.map(transformColumnsForAgGrid),
});
