<template>
  <asterix-section :status="sectionStatus" :is-loading="isLoadingSection">
    <template #header-right>
      <div>
        <sun-date-picker-v2
          id="analytics-filter-date-range"
          :key="datepickerKey"
          ref="datePicker"
          :value="quickFiltersDate"
          :max-date="limitDateRange.max"
          no-header
          no-calendar-icon
          :range="true"
          show-date-list
          :date-list="dateList"
          @change="onQuickFiltersDate($event.value)"
          @select:date-list-option="selectDateOption"
        />
      </div>
    </template>
    <template #content>
      <div class="flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center">
        <sun-button
          variant="pill"
          color="orange"
          class="mr-4 px-3 custom-p-1 text-xs bg-gray-700 h-6 flex"
          :class="{ 'hover:bg-gray-800': hasFilters }"
          :disabled="!hasFilters"
          @click="resetFilters"
        >
          <span class="mr-1">
            <trash-svg class="w-4 inline" />
          </span>
          <span class="whitespace-no-wrap"> Reset filters </span>
        </sun-button>
        <div class="h-10 mb-2 breakdown lg:mb-0 flex items-center">
          <breakdown-wrapper
            v-model="breakdown"
            label="Select Breakdowns"
            :items="breakdownOptions"
            hide-secondary
            horizontal-grow
            :disabled="breakdownDisabled"
            @input="onBreakdownHandler"
            @reset="resetBreakdown"
          />
        </div>
        <div class="flex flex-row items-center mb-2 ml-5 lg:mb-0">
          <asterix-dropdown
            button-classes="flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center"
            :disabled="isLoading"
            :options="reportOptions"
            @change="handlerReport"
          >
            <span class="block w-4 mr-2">
              <download-svg />
            </span>
            <template #dropdown-header>
              <p class="p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap">Download as</p>
            </template>
            <template #icon="state">
              <span class="transition-100" :class="!state.visible ? 'rotate-180' : ''">
                <up-svg class="w-2 h-2" :class="state.disabled ? 'text-gray-400' : 'text-gray-500'" />
              </span>
            </template>
          </asterix-dropdown>
        </div>
      </div>

      <div class="flex flex-col w-full mb-8">
        <ag-grid-vue
          :key="`DealsOtt_${tableKey}`"
          style="height: 500px"
          class="ag-theme-alpine"
          pagination
          animate-rows
          :column-defs="tableColumnsDefinition"
          :column-types="columnTypes"
          :auto-group-column-def="autoGroupColumnDef"
          :row-data="rowData"
          :scrollbar-width="10"
          :suppress-agg-func-in-header="true"
          :default-col-def="defaultColDef"
          :row-class-rules="rowClassRules"
          group-display-type="singleColumn"
          @grid-ready="newGridReady"
          @row-group-opened="rowGroupOpened"
        />
      </div>

      <analytics-total-loader v-if="isLoadingTotals" :columns="totalsColumnNames" />
      <box v-else-if="totals" class="mb-8">
        <box-column
          v-for="(col, $i) in totalsColumn"
          :key="`box-column-${$i}`"
          :title="col.name"
          :value="totals[col.id] | valueToLocalString"
        />
        <box-column
          v-if="currencySelected === 0"
          title="Net Revenue"
          :value="totals['netRevenue'] | currencyEurFilter(notAvailable, 'es-es')"
        />
        <box-column
          v-else-if="currencySelected === 1"
          title="Net Revenue"
          :value="totals['netRevenueUsd'] | currencyUsdFilter(notAvailable, 'es-es')"
        />
      </box>
    </template>
  </asterix-section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import { format, addMonths } from 'date-fns';
import { CONFIG } from './config';
import { CUSTOM_OPTION, COMMON_ID_VIEW, BREAKDOWN_COLUMNS, COLUMNS } from '../config';
import { getTableV2, getAnalyticsDealTotals } from '@/services/modules/dashboardSSP/analytics';
import AsterixSection from '@/components/templates/AsterixSection';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { ANALYTICS_REPORT_TOAST, SET_ANALYTICS_REPORT_TOAST } from '@/store/modules/analyticsReportToast/keys';
import { BREAKDOWN, SAVE_BREAKDOWN } from '@/store/modules/filters/keys';
import { BREAKDOWN_VALUES } from '@/model/modules/dashboardSSP/analytics/BreakdownValues';
import { CONTEXTS } from '@/model/shared/contexts';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { indexMixin } from '@/mixins/index/indexMixin';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import { Toast } from '@/model/shared/Toast';
import { USER } from '@/store/modules/auth/keys';
import AnalyticsTotalLoader from '@/components/atoms/Loaders/AnalyticsTotalLoader';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import Box from '@/components/atoms/Box/Box';
import BoxColumn from '@/components/atoms/Box/BoxColumn';
import Breakdown from '@/model/shared/Breakdown';
import filtersMixin from '@/mixins/filters/filtersMixin';
import agTableUtils from '@/mixins/agGrid/agTableUtils';
import metaInfo from '@/mixins/common/metaInfo';
import metricFilter from '@/filters/metricFilter';
import { stringToDate, dateToYYYYMMDD } from '@/filters/dateFilters';
import DetailPagination from '@/model/shared/analytics/DetailPagination';
import currency from '@/filters/currency';
import AnalyticsCellOrchestrator from '@/components/organisms/modules/dashboardSSP/AnalyticsCellOrchestrator.vue';
import { deepClone } from '@/utils/deepClone';
import TrashSvg from '@/components/icons/TrashSvg';
import BreakdownWrapper from '@/components/molecules/modules/Analytics/Breakdown/BreakdownWrapper.vue';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import SunDatePickerV2 from '@/components/atoms/SunDatePickerV2/SunDatePickerV2.vue';

function thousandSeparator(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export default {
  name: 'DealsOtt',
  components: {
    SunDatePickerV2,
    TrashSvg,
    AgGridVue,
    AnalyticsTotalLoader,
    AsterixSection,
    AsterixDropdown,
    Box,
    BoxColumn,
    BreakdownWrapper,
    DownloadSvg: () => import('@/components/icons/DownloadSvg'),
    UpSvg: () => import('@/components/icons/UpSvg.vue'),
    // eslint-disable-next-line vue/no-unused-components
    AnalyticsCellOrchestrator,
  },
  filters: {
    hourMinuteFormat: value => format(stringToDate(value), 'HH:mm'),
    metricFilter,
    currencyEurFilter: value => {
      if (value === null || value === undefined) return 'N/A';
      return thousandSeparator(currency(value, undefined, 'es-es', 2, 'N/A'));
    },
    currencyUsdFilter: value => {
      if (value === null || value === undefined) return 'N/A';
      return thousandSeparator(currency(value, 'USD', 'es-es', 2, 'N/A'));
    },
    valueToLocalString: value => {
      if (value === null || value === undefined) return 'N/A';
      if (value % 1 === 0) {
        return thousandSeparator(value.toLocaleString('es-ES'));
      }
      return thousandSeparator(
        value.toLocaleString('es-ES', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
      );
    },
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        '[platformDeal.name]': undefined,
        range_start: undefined,
        range_end: undefined,
        compare_range_start: undefined,
        compare_range_end: undefined,
      },
    }),
    indexMixin,
    metaInfo,
    agTableUtils,
  ],
  gridColumnApi: null,
  data: () => ({
    tableKey: 0,
    sort: null,
    headers: CONFIG.headers,
    breakdownHeaders: CONFIG.breakdown_headers,
    breakdownOptions: CONFIG.breakdown,
    breakdown: new Breakdown(BREAKDOWN_VALUES.deal),
    previousBreakdown: new Breakdown(BREAKDOWN_VALUES.deal),
    datepickerKey: 0,
    defaultDate: CONFIG.defaultDate,
    dateList: CONFIG.dateList,
    isLoading: false,
    totals: {},
    reportOptions: [
      { id: 'CSV', name: 'CSV', value: 'CSV' },
      { id: 'XLSX', name: 'XLSX', value: 'XLSX' },
    ],
    totalsColumn: CONFIG.totalsColumn,
    cacheDetails: new Map(),
    detailPagination: new DetailPagination(30),
    currencySelected: 0,
    currencyOptions: CONFIG.currencyOptions,
    columnTypes: CONFIG.columnTypes,
    exportParams: CONFIG.exportParams,
    rowClassRules: CONFIG.rowClassRules,
    isLoadingTotals: true,
    subBreakdownMixNames: CONFIG.subBreakdownMixNames,
    rowModelType: 'serverSide',
    gridApi: null,
    gridColumnApi: null,
    rowData: [],
    notAvailable: 'N/A',
    hasFilters: false,

    detailsCache: [],
    primaryRowCache: [],
  }),
  computed: {
    BREAKDOWN_VALUES: () => BREAKDOWN_VALUES,
    BREAKDOWN_COLUMNS: () => BREAKDOWN_COLUMNS,
    COLUMNS: () => COLUMNS,
    ...mapGetters({
      user: USER,
      getStoredBreakdown: BREAKDOWN,
      reportToast: ANALYTICS_REPORT_TOAST,
    }),
    isLoadingSection() {
      return this.isLoading || this.isLoadingTotals;
    },
    storedBreakdown() {
      return this.getStoredBreakdown(this.$route.name);
    },
    defaultSort() {
      return { field: COLUMNS.NET_REVENUE_EUR.value, order: 'DESC' };
    },
    hasSecondaryBreakdown() {
      return !!this.breakdown.secondary;
    },
    primaryBreakdownCol() {
      return this.breakdownHeaders.find(header => header.breakdowns[0] === this.breakdown.primary);
    },
    secondaryBreakdownCol() {
      return this.breakdownHeaders.find(header => header.breakdowns[0] === this.breakdown?.secondary);
    },
    autoGroupColumnDef() {
      return {
        headerName: `${this.primaryBreakdownCol.headerName} / ${this.secondaryBreakdownCol?.headerName}`,
        minWidth: 200,
        type: this.primaryBreakdownCol.type,
        enableValue: true,
        field: this.secondaryBreakdownCol ? this.secondaryBreakdownCol.field : null,
        filterValueGetter: params => params.data[this.primaryBreakdownCol.field],
        cellRendererParams: {
          suppressCount: true,
        },
      };
    },
    totalsColumnNames() {
      return CONFIG.totalsColumn.map(col => col.name);
    },
    reportToastLoading() {
      return !!this.reportToast?.id;
    },
    contextUserRole() {
      return this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.DASHBOARD_SSP.id).role;
    },
    availableColumnsByBreakdown() {
      return this.breakdownHeaders
        .concat(this.headers)
        .filter(header => !header.rolesAllowed.length || header.rolesAllowed.includes(this.contextUserRole))
        .filter(header => !header.breakdowns.length || header.breakdowns.includes(this.breakdown.primary));
    },
    tableColumnsDefinition() {
      return [...this.availableColumnsByBreakdown].map(
        ({
          headerName,
          field,
          cellClassRules,
          visible,
          maxWidth,
          minWidth,
          width,
          filter,
          filterParams,
          type,
          pinned,
          sort,
          suppressSizeToFit,
          suppressAutoSize,
          comparator,
        }) => {
          const hasGroup = this.isGroupColumn(field);
          const isSecondary = this.isSecondaryGroupColumn(field);
          return {
            headerName,
            field,
            hide: hasGroup || isSecondary ? true : !visible,
            cellClassRules,
            maxWidth,
            minWidth,
            width,
            pinned,
            filter,
            filterParams,
            type,
            sort,
            suppressSizeToFit,
            suppressAutoSize,
            rowGroup: hasGroup,
            enableValue: isSecondary,
            comparator,
            aggFunc: this.customAggFunc,
          };
        }
      );
    },
    quickFiltersDate() {
      const rangeStart = this.filterQuick.range_start;
      const rangeEnd = this.filterQuick.range_end;

      const startDate = rangeStart ? stringToDate(rangeStart) : this.defaultDate.startDate;
      const endDate = rangeEnd ? stringToDate(rangeEnd) : this.defaultDate.endDate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      return { startDate, endDate };
    },
    breakdownDisabled() {
      return this.isLoading;
    },
    limitDateRange() {
      const { startDate } = this.quickFiltersDate;
      const max = addMonths(startDate, 6);
      return {
        max: max < CONFIG.limitMaxDate ? max : CONFIG.limitMaxDate,
      };
    },
    sortByDefault() {
      let returnSort;
      switch (this.breakdown.primary) {
        case BREAKDOWN_VALUES.deal:
          returnSort = { field: BREAKDOWN_COLUMNS.NAME.value, order: 'ASC' };
          break;
        case BREAKDOWN_VALUES.country:
          returnSort = { field: BREAKDOWN_COLUMNS.COUNTRY.value, order: 'ASC' };
          break;
        case BREAKDOWN_VALUES.platform:
          returnSort = { field: BREAKDOWN_COLUMNS.PLATFORM.value, order: 'ASC' };
          break;
        default:
          returnSort = { field: BREAKDOWN_COLUMNS.DATE.value, order: 'DESC' };
          break;
      }
      return returnSort;
    },
    defaultColDef() {
      return { ...CONFIG.defaultColDef };
    },
  },
  watch: {
    autoGroupColumnDef(newVal) {
      this.gridApi.setAutoGroupColumnDef(newVal);
    },
  },
  created() {
    window.addEventListener('resize', this.resizeColumns);
    this.setBreakdown();
    this.eventOrder(this.initialSort);
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted(COMMON_ID_VIEW);
    this.setDefaultDateFilter();
    this.sort = this.sortByDefault;
    this.getTotals();

    await this.updateSortInURL();
    await this.filtersUpdateFiltersOnStoreAndURL(COMMON_ID_VIEW);
    await this.getFirstLevelItems();
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeColumns);
  },
  methods: {
    ...mapActions({
      saveBreakdown: SAVE_BREAKDOWN,
      createToast: CREATE_TOAST,
      showReportToast: SET_ANALYTICS_REPORT_TOAST,
    }),
    async rowGroupOpened(event) {
      const agRowKey = this.gridApi.getModel().rowsToDisplay.find(row => row.key === event.node.key).key;
      let rowOpened;
      let itemId;
      let itemPrimary;
      let propertyToCheck;
      switch (this.breakdown.primary) {
        case BREAKDOWN_VALUES.deal:
          propertyToCheck = 'dealName';
          rowOpened = this.rowData.find(row => row[propertyToCheck] === agRowKey);
          itemId = rowOpened.externalDealId;
          itemPrimary = rowOpened.dealName;
          break;
        case BREAKDOWN_VALUES.platform:
          propertyToCheck = 'platformName';
          rowOpened = this.rowData.find(row => row[propertyToCheck] === agRowKey);
          itemId = rowOpened.platformId;
          itemPrimary = rowOpened.platformName;
          break;
        case BREAKDOWN_VALUES.date:
          propertyToCheck = 'date';
          rowOpened = this.rowData.find(row => row[propertyToCheck] === agRowKey);
          itemId = rowOpened.date;
          itemPrimary = rowOpened.date;
          break;
        case BREAKDOWN_VALUES.country:
          propertyToCheck = 'country';
          rowOpened = this.rowData.find(row => row[propertyToCheck] === agRowKey);
          itemId = rowOpened.country;
          itemPrimary = rowOpened.country;
          break;
        default:
          propertyToCheck = 'breakdown';
          rowOpened = this.rowData.find(row => row[propertyToCheck] === agRowKey);
          itemId = rowOpened.breakdown;
          itemPrimary = rowOpened.breakdown;
          break;
      }
      if (rowOpened.alreadyOpen) return null;
      rowOpened.alreadyOpen = true;
      this.primaryRowCache = this.primaryRowCache.concat(rowOpened);

      this.gridApi?.hideOverlay();
      this.gridApi?.showLoadingOverlay();

      const data = await this.getSecondLevelItems(itemId);

      data.map(row => (row[propertyToCheck] = itemPrimary));
      this.gridApi.applyTransaction({ add: data });
      if (data.length) this.gridApi.applyTransaction({ remove: [rowOpened] });

      this.gridApi?.hideOverlay();
      this.detailsCache = this.detailsCache.concat(data);
    },
    async onBreakdownHandler() {
      if (this.previousBreakdown === this.breakdown) return null;
      if (this.previousBreakdown.primary !== this.breakdown.primary) {
        await this.getFirstLevelItems();
      } else if (this.previousBreakdown.secondary !== this.breakdown.secondary) {
        this.gridApi.collapseAll();
        this.primaryRowCache.forEach(row => {
          row.alreadyOpen = false;
        });
        this.gridApi.applyTransaction({ add: this.primaryRowCache });
        this.gridApi.applyTransaction({ remove: this.detailsCache });
        this.detailsCache = [];
        this.primaryRowCache = [];
      }
      this.previousBreakdown = deepClone(this.breakdown);
      this.resetFilters();
    },
    setSortDefaultOnTable() {
      this.breakdownHeaders.forEach(header => {
        if (header.value === this.sortByDefault.field) {
          this.gridColumnApi.applyColumnState({
            state: [{ colId: header.field, sort: this.sortByDefault.order.toLowerCase() }],
            defaultState: { sort: null },
          });
        }
      });
    },
    newGridReady({ api, columnApi }) {
      this.gridApi = api;
      this.gridColumnApi = columnApi;
      this.gridApi.hideOverlay();
      if (this.tableKey === 0) {
        this.gridApi.hideOverlay();
        this.gridApi.showLoadingOverlay();
      }
    },
    async getFirstLevelItems() {
      this.isLoading = true;
      this.resetFilters();
      this.gridApi?.hideOverlay();
      this.gridApi?.showLoadingOverlay();
      const auxParams = this.setParamsToTable();
      auxParams.page = this.currentPage;
      auxParams.itemsPerPage = 2000;
      auxParams.addFilter('breakdown', this.breakdown.primary);
      auxParams.addFilter('analyticsDeal.type', CONFIG.DEAL_OTT);
      auxParams.addSort(this.sortByDefault.field, this.sortByDefault.order);
      try {
        const { data } = await getTableV2(this.activeClient.id, auxParams);
        this.rowData = data;
        this.gridApi?.hideOverlay();
        this.tableKey++;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.sectionStatus = SECTION_STATUS.ERROR;
          await this.createToast(Toast.error(`We can not get analytic`, error));
        }
      }
      this.isLoading = false;
    },
    async getSecondLevelItems(itemId) {
      const params = this.setParamsToTable();
      params.itemsPerPage = 2000;
      params.addFilter('analyticsDeal.type', CONFIG.DEAL_OTT);
      params.addFilter('breakdown', this.breakdown.primary);
      params.addFilter('subBreakdown', this.breakdown.secondary);
      switch (this.breakdown.primary) {
        case BREAKDOWN_VALUES.date:
          params.addFilter('range_start', new Date(itemId).setHours(0, 0, 0, 0));
          params.addFilter('range_end', new Date(itemId).setHours(23, 59, 59, 0));
          break;
        case BREAKDOWN_VALUES.country:
          params.addFilter('analyticsDeal.country', itemId);
          break;
        case BREAKDOWN_VALUES.platform:
          params.addFilter('platform.id', itemId);
          break;
        case BREAKDOWN_VALUES.deal:
          params.addFilter('analyticsDeal.externalDealId', itemId);
          break;
      }
      switch (this.breakdown.secondary) {
        case BREAKDOWN_VALUES.date:
          params.addSort(BREAKDOWN_COLUMNS.DATE.value, 'DESC');
          break;
        case BREAKDOWN_VALUES.country:
          params.addSort(BREAKDOWN_COLUMNS.COUNTRY.value, 'ASC');
          break;
        case BREAKDOWN_VALUES.platform:
          params.addSort(BREAKDOWN_COLUMNS.PLATFORM.value, 'ASC');
          break;
        case BREAKDOWN_VALUES.deal:
          params.addSort(BREAKDOWN_COLUMNS.NAME.value, 'ASC');
          break;
      }
      try {
        const { data } = await getTableV2(this.activeClient.id, params);
        return data;
      } catch {
        this.sectionStatus = SECTION_STATUS.ERROR;
      }
    },
    async changeFilters(filters) {
      this.cacheDetails.clear();
      await this.updateFilters(filters);
      this.getFirstLevelItems();
      this.getTotals();
    },

    async updateFilters(filters = []) {
      this.replaceQueryParams({ page: 1, breakdown: this.queryParams.breakdown || null });

      this.filterFind = filters;
      await this.filtersUpdateFiltersOnStoreAndURL(COMMON_ID_VIEW);
    },
    selectDateOption(option) {
      if (option === CUSTOM_OPTION) {
        setTimeout(() => this.$refs.datePicker.open(), 0);
      }
    },
    isGroupColumn(field) {
      if (!this.hasSecondaryBreakdown) return false;
      return (
        !!(this.breakdown.primary === BREAKDOWN_VALUES.deal && field === 'dealName') ||
        !!(this.breakdown.primary === BREAKDOWN_VALUES.date && field === 'date') ||
        !!(this.breakdown.primary === BREAKDOWN_VALUES.country && field === 'country') ||
        !!(this.breakdown.primary === BREAKDOWN_VALUES.platform && field === 'platformName')
      );
    },
    isSecondaryGroupColumn(field) {
      if (!this.hasSecondaryBreakdown) return false;
      return (
        !!(this.breakdown.secondary === BREAKDOWN_VALUES.deal && field === 'dealName') ||
        !!(this.breakdown.secondary === BREAKDOWN_VALUES.date && field === 'date') ||
        !!(this.breakdown.secondary === BREAKDOWN_VALUES.country && field === 'country') ||
        !!(this.breakdown.secondary === BREAKDOWN_VALUES.platform && field === 'platformName')
      );
    },
    async onQuickFiltersDate(value) {
      this.setFilterQuick(value || this.defaultDate);
      await this.changeFilters(this.filterFind);
    },
    setFilterQuick({ startDate, endDate }) {
      endDate.setHours(23, 59, 59, 0);
      startDate.setHours(0, 0, 0, 0);
      this.filterQuick.range_start = startDate.toISOString();
      this.filterQuick.range_end = endDate.toISOString();

      const { startDateCompare, endDateCompare } = this.getDateCompare(startDate, endDate);
      this.filterQuick.compare_range_start = startDateCompare.toISOString();
      this.filterQuick.compare_range_end = endDateCompare.toISOString();

      // TODO Is this necessary?
      this.datepickerKey++;
    },
    setDefaultDateFilter() {
      this.setFilterQuick(this.quickFiltersDate);
    },
    resetBreakdown() {
      this.breakdown = new Breakdown(BREAKDOWN_VALUES.deal);
    },
    setBreakdown() {
      const retrievedPrimary = this.queryParams.breakdown || this.storedBreakdown?.primary || this.breakdown.primary;
      const retrievedSecondary =
        this.queryParams.subBreakdown || this.storedBreakdown?.secondary || this.breakdown.secondary;

      this.breakdown.primary = retrievedPrimary;
      this.breakdown.secondary = retrievedSecondary;
    },
    async getTotals() {
      this.isLoadingTotals = true;
      try {
        const auxParams = this.setParamsToTable();
        auxParams.addFilter('client.id', undefined);
        auxParams.setBreakdown('deal');
        auxParams.addFilter('analyticsDeal.type', CONFIG.DEAL_OTT);

        this.totals = await getAnalyticsDealTotals(this.activeClient.id, auxParams);
      } catch (e) {
        this.createToast(Toast.error(`Oops! We couldn't get the totals`, e));
      }
      this.isLoadingTotals = false;
    },
    handlerReport({ value }) {
      const params = deepClone(this.exportParams);
      const date = dateToYYYYMMDD(new Date());
      if (value === 'CSV') {
        params.fileName = `${date}_deal-ott-analytics.csv`;
        this.gridApi.exportDataAsCsv(params);
      } else if (value === 'XLSX') {
        params.fileName = `${date}_deal-ott-analytics.xlsx`;
        this.gridApi.exportDataAsExcel(params);
      }
    },
    getDateCompare(startDate, endDate) {
      const MILLISECONDS_SECOND = 1000;
      const SECONDS_HOUR = 3600;
      const HOURS_DAY = 24;

      const difference = Math.round((endDate - startDate) / MILLISECONDS_SECOND / SECONDS_HOUR / HOURS_DAY);
      const startDateCompare = stringToDate(startDate);
      const endDateCompare = stringToDate(endDate);

      startDateCompare.setDate(startDateCompare.getDate() - difference);
      endDateCompare.setDate(endDateCompare.getDate() - difference);

      return { startDateCompare, endDateCompare };
    },
    onCurrencyChange({ name }) {
      this.headers.forEach(col => {
        col.visible = !col.currency || col.currency === name;
      });
      this.resizeColumns();
    },
    resizeColumns() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        });
      }
    },
    resetFilters() {
      this.gridApi?.setFilterModel(null);
    },
    updateActiveFilters() {
      this.hasFilters = !!this.gridApi?.isAnyFilterPresent();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';

.breakdown {
  min-width: 280px;
}

::v-deep .border-r-transparent {
  border-right-color: transparent;
}

::v-deep .date.z-10 {
  @apply z-0;
}
:deep(.ag-theme-alpine .ag-root-wrapper) {
  border: 0;
}
:deep(.ag-theme-alpine .ag-header) {
  @apply bg-gray-300 border-0;
}
:deep(.ag-theme-alpine .ag-header-cell) {
  @apply px-1;
}

:deep(.ag-theme-alpine .ag-header-cell-label),
:deep(.ag-theme-alpine .ag-cell-value) {
  @apply text-xs px-1;
}

:deep(.ag-theme-alpine .ag-floating-filter-button) {
  display: none;
}

:deep(.ag-theme-alpine .ag-watermark) {
  opacity: 0 !important;
  display: none !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  right: auto !important;
  bottom: auto !important;
  visibility: hidden !important;
  transition: none !important;
}

:deep(.ag-theme-alpine .ag-row.deal-starting) {
  @apply text-green-600 bg-green-100;
}

:deep(.ag-theme-alpine .ag-row.deal-ending) {
  @apply text-red-500 bg-red-100;
}

:deep(.ag-theme-alpine .ag-row.deal-starting [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-starting [col-id='ag-Grid-AutoColumn']),
:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']) {
  @apply pl-5;
}

:deep(.ag-theme-alpine .ag-row.deal-starting [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-starting [col-id='ag-Grid-AutoColumn']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']:before) {
  content: '';
  position: absolute;
  top: 14px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  @apply bg-green-600;
}

:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [col-id='ag-Grid-AutoColumn']),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [aria-colindex='1']),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [col-id='ag-Grid-AutoColumn']) {
  @apply pl-0;
}

:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-starting.ag-row-level-1 [col-id='ag-Grid-AutoColumn']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending.ag-row-level-1 [col-id='ag-Grid-AutoColumn']:before) {
  left: 38px;
}

:deep(.ag-theme-alpine .ag-row.deal-ending [aria-colindex='1']:before),
:deep(.ag-theme-alpine .ag-row.deal-ending [col-id='ag-Grid-AutoColumn']:before) {
  @apply bg-red-600;
}
</style>
